import React from 'react';
import styled from 'styled-components';
import { HowToPayWithFenaContent } from '@/pages/how-to-pay-with-fena';
import FirstViewport from '@/components/FirstViewport';
import viewport1image from '../assets/img/page17viewport1.png';
import viewport2image from '../assets/img/page17viewport2.png';
import viewport3image from '../assets/img/page17viewport3.png';
import viewport4image from '../assets/img/page17viewport4.png';
import page17Vp1Bg from '../assets/img/page4Vp1Bg.png';
import bannerBg from '../assets/img/bannerHomePageBg.png';
import { Description, H1, H2, P, Strong } from '@/components/Typography';
import ButtonDefault from '@/components/ButtonDefault';
import Section from '@/components/Section';
import Banner from '@/components/Banner';
import BottomMessage from '@/components/BottomMessage';

const FirstViewportWrapper = styled.div`
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const ViewportsBg = styled.section`
  background: linear-gradient(180deg, #ffffff 0%, #f4f7f9 100%);
`;
const SecondViewport = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: calc(100vh - 70px - 5rem * 2);
  padding-bottom: 0;
  @media (max-width: 1200px) {
    height: auto;
  }
`;

const LeftSideContent = styled.div`
  flex: 1 1 50%;
`;

const SectionTitle = styled(H2)`
  margin: 1rem 0 1rem 0;
`;

const DescriptionSection = styled.div`
  margin-top: 2rem;
`;
const DescriptionItem = styled.div`
  display: flex;
`;
const Circle = styled.div`
  border: 2px solid #6c6c8a;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  flex-grow: 0;
`;
const Dash = styled.div`
  border: 1px dashed #c2cedb;
  height: 100%;
`;
const CircleAndDash = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
`;

const DescriptionDashed = styled(P)`
  margin-top: -0.4rem;
  margin-bottom: 2rem;
`;
const RightImage = styled.img`
  max-height: 100%;
  padding-left: 1rem;
  @media (max-width: 1200px) {
    max-height: auto;
    width: 100%;
    margin-top: 2rem;
    padding-left: 0;
  }
`;
const ThirdViewport = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: calc(100vh - 70px - 5rem * 2);
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    height: auto;
  }
`;

const FourthViewport = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: calc(100vh - 70px - 5rem * 2);
  padding-top: 0;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    height: auto;
    margin-top: 5rem;
  }
`;
const FifthViewport = styled(Section)`
  padding-top: 0;
`;
interface HowToPayWithFenaProps {
  content: HowToPayWithFenaContent;
}

const HowToPayWithFenaContainer: React.FunctionComponent<
  HowToPayWithFenaProps
> = ({
  content: { viewport1, viewport2, viewport3, viewport4, viewport5 },
}) => (
  <>
    <FirstViewportWrapper resource={page17Vp1Bg}>
      <FirstViewport img={viewport1image}>
        <H1>
          {viewport1.title1}
          <span className="accent-text"> {viewport1.title2}</span>
        </H1>
        <Strong>{viewport1.description1}</Strong>
        <Description>{viewport1.description2}</Description>
        <ButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </ButtonDefault>
      </FirstViewport>
    </FirstViewportWrapper>
    <ViewportsBg>
      <SecondViewport>
        <LeftSideContent>
          <Strong className="accent-text">{viewport2.label}</Strong>
          <SectionTitle>{viewport2.title}</SectionTitle>
          <Strong>{viewport2.description}</Strong>
          <DescriptionSection>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
                <Dash />
              </CircleAndDash>
              <DescriptionDashed>{viewport2.subDescription1}</DescriptionDashed>
            </DescriptionItem>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
                <Dash />
              </CircleAndDash>
              <DescriptionDashed>{viewport2.subDescription2}</DescriptionDashed>
            </DescriptionItem>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
              </CircleAndDash>
              <DescriptionDashed>{viewport2.subDescription3}</DescriptionDashed>
            </DescriptionItem>
          </DescriptionSection>
        </LeftSideContent>
        <RightImage src={viewport2image} alt="application screnshot" />
      </SecondViewport>

      <ThirdViewport>
        <RightImage src={viewport3image} alt="application screnshot" />
        <LeftSideContent>
          <Strong className="accent-text">{viewport3.label}</Strong>
          <SectionTitle>{viewport3.title}</SectionTitle>
          <DescriptionSection>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
                <Dash />
              </CircleAndDash>
              <DescriptionDashed>{viewport3.subDescription1}</DescriptionDashed>
            </DescriptionItem>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
                <Dash />
              </CircleAndDash>
              <DescriptionDashed>{viewport3.subDescription2}</DescriptionDashed>
            </DescriptionItem>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
              </CircleAndDash>
              <DescriptionDashed>{viewport3.subDescription3}</DescriptionDashed>
            </DescriptionItem>
          </DescriptionSection>
        </LeftSideContent>
      </ThirdViewport>

      <FourthViewport>
        <LeftSideContent>
          <Strong className="accent-text">{viewport4.label}</Strong>
          <SectionTitle>{viewport4.title}</SectionTitle>
          <Strong>{viewport4.description}</Strong>
          <DescriptionSection>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
                <Dash />
              </CircleAndDash>
              <DescriptionDashed>{viewport4.subDescription1}</DescriptionDashed>
            </DescriptionItem>
            <DescriptionItem>
              <CircleAndDash>
                <Circle />
              </CircleAndDash>
              <DescriptionDashed>{viewport4.subDescription2}</DescriptionDashed>
            </DescriptionItem>
          </DescriptionSection>
        </LeftSideContent>
        <RightImage src={viewport4image} alt="application screnshot" />
      </FourthViewport>

      <FifthViewport>
        <Banner
          bgSrc={bannerBg}
          label={viewport5.bannerLabel}
          description={viewport5.bannerDescription}
          appStoreButtonUrl={viewport5.appStoreButton.url}
          googlePlayButtonUrl={viewport5.googlePlayButton.url}
        />
        <BottomMessage
          title1={viewport5.lowerTitle1}
          title2={viewport5.lowerTitle2}
          text={viewport5.lowerText}
          button={viewport5.button}
        />
      </FifthViewport>
    </ViewportsBg>
  </>
);

export default HowToPayWithFenaContainer;
