import React from 'react';
import Main from '../containers/Layout';
import howToPayWithFenaContent from '../../content/pages/how-to-pay-with-fena.yml';
import HowToPayWithFenaContainer from '@/containers/How-to-pay-with-fena';

export interface HowToPayWithFenaContent {
  viewport1: {
    title1: string;
    title2: string;
    description1: string;
    description2: string;
    button: {
      label: string;
      url: string;
    };
  };

  viewport2: {
    label: string;
    title: string;
    description: string;
    subDescription1: string;
    subDescription2: string;
    subDescription3: string;
  };

  viewport3: {
    label: string;
    title: string;
    subDescription1: string;
    subDescription2: string;
    subDescription3: string;
  };
  viewport4: {
    label: string;
    title: string;
    description: string;
    subDescription1: string;
    subDescription2: string;
  };
  viewport5: {
    bannerLabel: string;
    bannerDescription: string;
    appStoreButton: {
      url: string;
    };
    googlePlayButton: {
      url: string;
    };
    lowerTitle1: string;
    lowerTitle2: string;
    lowerText: string;
    button: {
      label: string;
      url: string;
    };
  };
}
const HowToPayWithFenaPage: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4, viewport5 } =
    howToPayWithFenaContent as unknown as HowToPayWithFenaContent;
  return (
    <Main>
      <HowToPayWithFenaContainer
        content={{ viewport1, viewport2, viewport3, viewport4, viewport5 }}
      />
    </Main>
  );
};

export default HowToPayWithFenaPage;
